<template>
  <div style="float:left;width:100%">
    <van-row
      class="main"
      style="float:left;margin-top:1rem;border-bottom:1px solid #d3d2d2;height:5rem"
    >
      <van-col span="7" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;height:80%"
        />
      </van-col>
      <van-col span="15" offset="1" class="headtext">
        一条马ERP手机APP下载
      </van-col>
    </van-row>
    <div style="width:100%;float:left;margin-top:10px">
      <div style="width:50%;margin:auto;">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;"
        />
      </div>
      <div>
        <van-button type="success" style="display:block;margin:auto;width:95%;"
          >安卓立即下载</van-button
        >
      </div>
    </div>
    <div style="width:100%;float:left;margin-top:20px">
      <div style="width:50%;margin:auto;">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;"
        />
      </div>
      <div>
        <van-button icon='' type="success" style="display:block;margin:auto;width:95%;"
          >苹果立即下载</van-button
        >
      </div>
      <div
        style="font-size:0.5rem;text-align:center;position: fixed;bottom: 0;width:100%"
      >
        <p><font style="color:#153481">粤ICP备17109051号</font> © 深圳一条马科技有限公司 版权所有</p>
        <p>深圳市龙华新区万得孚共享办公3331 1379827931</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Grid, GridItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Uploader } from "vant";
import { Button } from "vant";
export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [VanImage.name]: VanImage,
    [Form.name]: Form,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
  },

  data() {
    return {
      uploader: [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }],
    };
  },

  computed: {},

  methods: {},
};
</script>

<style scoped>
.headtext {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}
</style>
